import useSWR from "swr";
import {fetchSWR, patch, post, trash} from "../apiClient";
import {Comment} from "./model";

interface CommentParams {
    limit?: number;
    userID?: string;
    skip?: boolean;
    parentID?: string;
}

export const useSWRComments = (params?: CommentParams) => {
    const {skip} = params || {};

    // Build query parameters dynamically
    const queryParams = new URLSearchParams();

    if (params?.limit !== undefined) {
        queryParams.append('limit', params.limit.toString());
    }

    if (params?.userID) {
        queryParams.append('userID', params.userID);
    }

    if (params?.parentID) {
        queryParams.append('parentID', params.parentID);
    }

    const queryString = queryParams.toString();

    return useSWR(skip ? undefined :
            `/wallComment?${queryString}`,
        (url: any) => fetchSWR<{
            items: Comment[]
        }>({input: url}), {
            errorRetryCount: 0,
            errorRetryInterval: 0,
        }
    );
}

export const useCommentByID = (id?: string) => {
    return useSWR(!id ? undefined :
            `/wallComment/${id}`,
        (url: any) => fetchSWR<Comment>({input: url}), {
            errorRetryCount: 0,
            errorRetryInterval: 0,
        }
    );
}


interface TotalCommentsParams {
    userID?: string;
    parentID?: string;
}

export const useTotalComments = (params?: TotalCommentsParams) => {

    const queryParams = new URLSearchParams();

    if (params?.userID) {
        queryParams.append('userID', params.userID);
    }

    if (params?.parentID) {
        queryParams.append('parentID', params.parentID);
    }

    return useSWR(
        '/wallComment/count?' + queryParams.toString(),
        (url) => fetchSWR<{ count: number }>({input: url}), {
            errorRetryCount: 0,
            errorRetryInterval: 0,
        }
    );
}

interface CreateCommentParams {
    channelID?: string;
    userID?: string;
    parentID?: string | null;
    text?: string;
    grafitiBase64?: string;
}

export const createComment = async (params: CreateCommentParams) => {
    const response = await post({
        input: '/wallComment',
        data: params,
        init:{
            headers: {
                'Content-Type': 'application/json',
            }
        }
    });
    return (await response.json()) as Comment;

}

interface EditCommentParams {
    id: string;
    text?: string;
}


export const editComment = async (params: EditCommentParams) => {
    const response = await patch({
        input: '/wallComment/' + params.id,
        data: {
            text: params.text
        }
    });
    return (await response.json()) as Comment;

}


export const removeComment = async (id: string) => {
    await trash({
        input: '/wallComment/' + id
    });
}


export async function authHandler(initDataRaw: string) {
    const response = await post({
        input: '/auth',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            init_data: initDataRaw
        }
    });
    return (await response.json()) as { token: string };
}
