import styled from "styled-components";
import {Subscription} from "../api/requests/model";
import {Link, useNavigate} from "react-router-dom";
import {getTgName} from "../ustils/getTgName";
import {useUserStore} from "../store/user-strore";


interface Props {
    subscriptions: Subscription[]
}

export const Subscriptions = (props: Props) => {


    const {user} = useUserStore()

    const nav = useNavigate()
    return (
        <Container
        >
            {props?.subscriptions?.map((item, index) => (
                <ItemWrapper
                    onClick={() => {
                        if (item.toUserID === user?.id) {
                            nav(`/`)
                        } else {
                            nav(`/user/${item.toUserID}`)
                        }
                    }}

                    key={index}>
                    {item.toUser?.photoURL && <Avatar src={item.toUser?.photoURL}/>}
                    {!item.toUser?.photoURL && <Avatar src={'/images/avatar.png'}/>} <Name>

                    {getTgName({
                        user: item.toUser,
                        cutTo: 24
                    })}
                </Name>
                </ItemWrapper>
            ))}
        </Container>

    )
}


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    overflow-x: scroll;
    height: 88px;
    min-height: 88px;
    gap: 4px;
    text-decoration: none;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`

const ItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 77px;
    min-width: 77px;
    text-decoration: none;

`


const Avatar = styled.img`
    width: 44px;
    height: 44px;
    min-width: 44px;
    border-radius: 50%;
    object-fit: cover;

`

const Name = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`