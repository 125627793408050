import styled, {keyframes} from "styled-components";
import {Comment as IComment} from "../api/requests/model";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {getTgName} from "../ustils/getTgName";
import {Link, useNavigate} from "react-router-dom";
import {useUserStore} from "../store/user-strore";

interface Props extends IComment {
    isChild?: boolean;
    onDelete: (id: string) => void;
    onEdit: (id: string, text: string) => void;
    isPost?: boolean;
    onReply?: () => void;
}

const formatDate = (date: string) => {
    return moment(date).format('HH:mm DD.MM');
};

export const Comment = (props: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const commentRef = useRef<HTMLDivElement>(null); // Create a ref for the comment container

    const {user} = useUserStore();

    const navigate = useNavigate()

    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    const adjustHeight = () => {
        if (inputRef.current) {
            inputRef.current.style.height = "auto";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [isEditing]);


    const handleDelete = (id: string) => {
        setTimeout(() => props.onDelete(id));
    };
    const onNavigate = (id: string) => {
        if (user?.id === id) {
            navigate(`/`)
        } else {
            navigate(`/user/${id}`)
        }
    }

    useEffect(() => {
        adjustHeight();
        if (isEditing) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [isEditing]);

    return (
        <>
            <Row ref={commentRef} isChild={props.isChild} isDeleting={false}>
                {props?.authorUser?.photoURL && <Avatar
                    onClick={() => onNavigate(props.authorUser.id)}
                    src={props.authorUser.photoURL}/>}
                {!props?.authorUser?.photoURL && <Avatar
                    onClick={() => onNavigate(props.authorUser.id)}
                    src={'/images/avatar.png'}/>}
                <Column>
                    <Column gap={2}>
                        <UserName
                            onClick={() => onNavigate(props.authorUser.id)}
                        >
                            {getTgName({
                                user: props.authorUser,
                                cutTo: 24
                            })}
                        </UserName>
                        <Date>
                            {formatDate(props.createdAt)}
                        </Date>
                    </Column>
                    {props?.text &&
                        <>
                            {isEditing &&
                                <Input
                                    defaultValue={props.text}
                                    ref={inputRef} onInput={adjustHeight}
                                />
                            }
                            {!isEditing &&
                                <Text dangerouslySetInnerHTML={{__html: props.text.replace(/\n/g, '<br/>')}}/>
                            }
                        </>
                    }
                    {props?.grafitiURL &&
                        <img src={props.grafitiURL} style={{width: '100%', height: 'auto'}}/>
                    }
                    <Buttons>
                        {isEditing &&
                            <>
                                <ActionButton
                                    color={'#4A708D'}
                                    onClick={() => {

                                        props.onEdit(props.id, inputRef.current?.value || '');
                                        setIsEditing(false);

                                    }}>
                                    Сохранить
                                </ActionButton>
                                <ActionButton
                                    color={'#CC6565'}
                                    onClick={() => {
                                        setIsEditing(false);
                                    }}>
                                    Отменить
                                </ActionButton>
                            </>
                        }

                        {(props?.onReply && !isEditing) &&
                            <ActionButton onClick={props.onReply}>
                                Ответить
                            </ActionButton>
                        }
                        {(props?.isPost && !isEditing) &&
                            <Link
                                className={'reply'}
                                style={{textDecoration: 'none'}} to={`/comment/${props.id}`}>
                                <ActionButton isUnderlined>
                                    Комментарии {props.childrenCount}
                                </ActionButton>
                            </Link>
                        }
                        {((props?.authorUser.id === user?.id || props?.userID === user?.id) && !isEditing) &&
                            <>
                                {props?.authorUser.id === user?.id && props?.text &&
                                    <ActionButton
                                        isUnderlined
                                        onClick={() => {
                                            setIsEditing(!isEditing);
                                        }}>
                                        Редактировать
                                    </ActionButton>
                                }

                                <DeleteButton onClick={() => handleDelete(props.id)}>
                                    Удалить
                                </DeleteButton>
                            </>
                        }
                    </Buttons>
                </Column>
            </Row>

        </>
    );
};


const Input = styled.textarea`
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    box-sizing: border-box;
    resize: none;
    border: none;
    overflow: hidden;
    width: 100%;

    &::placeholder {
        color: #999;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &:focus {
        outline: none;
    }
`;


const Row = styled.div<{ isChild?: boolean; isDeleting: boolean }>`
    display: flex;
    width: ${props => props.isChild ? 'calc(100% - 44px)' : '97%'};
    padding-top: ${props => props.isChild ? '12px' : '0'};
    margin-top: ${props => props.isChild ? '12px' : '12px'};
    margin-bottom: ${props => props.isChild ? '12px' : '12px'};

    border-top: ${props => props.isChild ? '1px dashed #E5E5E5' : 'none'};
    animation: ${props => props.isDeleting ? fadeOut : 'none'} 0.3s forwards;
    ${props => props.isDeleting && 'animation-fill-mode: forwards;'}
    margin-left: ${props => !props.isChild ? '12px' : '0'};
    box-sizing: border-box;

`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-100%);
        display: none;
    }
`;
const Buttons = styled.div`
    display: flex;
    gap: 8px;
`;

const Avatar = styled.img`
    width: 44px;
    height: 44px;
    min-width: 44px;
    border-radius: 44px;
    margin-right: 8px;
    object-fit: cover;
    cursor: pointer;
`;

const Column = styled.div<{ gap?: number }>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: ${props => props.gap || 8}px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden; /* Prevent child elements from overflowing */
    cursor: pointer;


`;

const UserName = styled.div`
    color: #467394;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    cursor: pointer;
`;

const Date = styled.div`
    color: #A3A3A3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const Text = styled.div`
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 100%;
    padding-right: 8px;
`;


const ActionButton = styled.div<{ isUnderlined?: boolean; color?: string }>`
    color: ${props => props.color || '#4A708D'};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    text-decoration-line: ${props => props.isUnderlined ? 'underline' : 'none'};

`;

const DeleteButton = styled.div`
    color: #CC6565;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
    cursor: pointer;
`;
