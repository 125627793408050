import styled from "styled-components";

export const EmptyPosts = ({isAnotherUser}: { isAnotherUser?: boolean }) => {
    return (
        <Container className={'posts'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
                <path
                    d="M49.4369 0H15.563C10.3485 0 6.12134 4.22719 6.12134 9.44168V54.5583C6.12134 59.7728 10.3485 64 15.563 64H49.4369C54.6514 64 58.8786 59.7728 58.8786 54.5583V9.44168C58.8786 4.22719 54.6514 0 49.4369 0ZM24.6996 17.8593H40.3002C41.5035 17.8583 42.4797 18.8331 42.4807 20.0365C42.4817 21.2398 41.5069 22.2162 40.3036 22.217C40.3025 22.217 40.3013 22.217 40.3002 22.217H24.6996C23.4962 22.217 22.5207 21.2415 22.5207 20.0382C22.5207 18.8348 23.4962 17.8593 24.6996 17.8593ZM45.9144 46.1408H19.0855C17.8822 46.1408 16.9066 45.1653 16.9066 43.962C16.9066 42.7586 17.8822 41.7831 19.0855 41.7831H45.9144C47.1177 41.7831 48.0933 42.7586 48.0933 43.962C48.0933 45.1653 47.1177 46.1408 45.9144 46.1408ZM45.9144 34.1788H19.0855C17.8822 34.1788 16.9066 33.2033 16.9066 32C16.9066 30.7967 17.8822 29.8211 19.0855 29.8211H45.9144C47.1177 29.8211 48.0933 30.7967 48.0933 32C48.0933 33.2033 47.1177 34.1788 45.9144 34.1788Z"
                    fill="#D6DADE"/>
            </svg>
            <Title>
                 {isAnotherUser ? 'У пользователя нет записей' : 'У вас нет записей'}
            </Title>
            <Decription>
                 {isAnotherUser ? 'Опишите что-то из своей жизни или ожидайте новое сообщение от ваших друзей.' : 'Опишите что-то из своей жизни или ожидайте новое сообщение от ваших друзей.'}
            </Decription>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;


`

const Title = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
`


const Decription = styled.div`
    color: #A0B2BF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`