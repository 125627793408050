import {useSWRComments} from "../api/requests/comment";
import {useCommentsStore} from "../store/comments-store";
import {useEffect} from "react";


interface Props {
    userID?: string;

}

export const useComments = (props: Props) => {
    const {data, mutate, isLoading} = useSWRComments({
        limit: 300,
        userID: props.userID,
        skip: !props.userID
    });
    const setComments = useCommentsStore(state => state.setComments);

    useEffect(() => {
        if (data) {
            setComments(() => data?.items);
        }
    }, [data, setComments]);

    return {
        comments: useCommentsStore(state => state.comments),
        isLoading,
        mutate,
        setComments
    }
}
