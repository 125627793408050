import {authHandler} from "./requests/user-info";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {useTokenStore} from "../store/token-store";


interface ApiUrls {
    auth: string;
    dashboard: string;
    promo: string;
}


export interface ApiError {
    code: number;
    message: string;
}

interface FetchOptions<T> extends RequestInit {
    data?: T;
    headers?: HeadersInit;
}


interface PostParams<T> {
    input: RequestInfo;
    data?: T;
    init?: FetchOptions<T>;
    serviceName?: keyof ApiUrls;
}

export async function post<T>(
    {input, data, init}: PostParams<T>
): Promise<Response> {
    const {headers, ...restInit} = init ?? {};

    return fetcher(input, {
        method: "POST",
        headers: {
            ...(headers || {}),
        },
        ...restInit,
        body: data instanceof FormData ? data : JSON.stringify(data),
    });
}


export async function patch<T>(
    {input, data, init}: PostParams<T>
): Promise<Response> {
    const {headers, ...restInit} = init ?? {};

    return fetcher(input, {
        method: "PATCH",
        headers: {
            ...(headers || {}),
        },
        ...restInit,
        body: data instanceof FormData ? data : JSON.stringify(data),
    });
}


export async function trash<T>(
    {input, data, init}: PostParams<T>
): Promise<Response> {
    const {headers, ...restInit} = init ?? {};

    return fetcher(input, {
        method: "DELETE",
        headers: {
            ...(headers || {}),
        },
        ...restInit,
        body: data instanceof FormData ? data : JSON.stringify(data),
    });
}

export const fetcher = async <T>(
    input: RequestInfo,
    options?: FetchOptions<T>,
): Promise<Response> => {

    const apiURL = `https://api.dyrovverysteny.net`

    let requestOptions = {
        ...options,
        headers: {
            "Content-Type": "application/json",
             "Authorization": `Bearer ${useTokenStore.getState().token}`,
            ...options?.headers,
        },
    };

    if (options?.data) {
        requestOptions.body = JSON.stringify(options.data);
    }

    try {
        console.log(`${apiURL}${input}`)
        const response = await fetch(`${apiURL}${input}`, requestOptions);
        console.log(response)
        if (response.status === 401 || response.status === 422) {

            // localStorage.removeItem('token');

            // const resp = await authHandler(retrieveLaunchParams().initDataRaw as string);
            // localStorage.setItem('token', resp.result.auth.token);
            // return fetcher(input, options);


        } else if (response.status >= 400) {
            const result = (await response.json()) as ApiError;

            throw new Error(result.message);
        }
        return response;
    } catch (error) {
        console.log(error)
        throw error;
    }
};

interface FetchSWR<T> {
    input: RequestInfo;
    init?: FetchOptions<T>;
    serviceName?: keyof ApiUrls;
}

export async function fetchSWR<T>({
                                      input,
                                      init,
                                  }: FetchSWR<T>): Promise<T> {

    const response = await fetcher(input);
    return await response.json();
}


