import styled, {keyframes} from 'styled-components';
import {LoaderIcon} from "./icons/loader";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const SpinningLoaderIcon = styled(LoaderIcon)`
    animation: ${spin} 1s linear infinite;
`;


interface Props {
    isFullPage?: boolean;
}

export const Loader = (props: Props) => {

    if (props.isFullPage) {
        return (
            <Container>
                <SpinningLoaderIcon style={{width: 36, height: 36}}/>
            </Container>
        );
    }


    return <SpinningLoaderIcon/>;
};


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background: white;
    z-index: 0;
`
