import {fetchSWR, post} from "../apiClient";
import useSWR from "swr";
import {PaginatedResponse, Response} from "./model";


export interface User {
    "id": string
    "telegramID"?: string
    "username": string
    "name": string
    "photoURL"?: string
    "subscriptionCount"?: number
    subscribersCount?: number
    "createdAt"?: string
    "updatedAt": string
    isSubscribed?: boolean
    wallCommentsCount?: number
}


export const useSWRUserInfo = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/myUser',
        (url: any) => fetchSWR<User>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


interface UsersParams {
    limit?: number;
    skip?: boolean;
    orderBy?: string;
    searchByNameQuery?: string;
}

export const useUsers = (params?: UsersParams) => {
    const {skip} = params || {};
    const queryParams = new URLSearchParams();

    if (params?.limit !== undefined) {
        queryParams.append('limit', params.limit.toString());
    } else {
        queryParams.append('limit', '100');
    }

    if (params?.orderBy) {
        queryParams.append('orderBy', params.orderBy);
    }

    if (params?.searchByNameQuery) {
        queryParams.append('searchByNameQuery', params.searchByNameQuery);
    }

    return useSWR(skip ? undefined :
            `/userWithSub?${queryParams.toString()}`,
        (url: any) => fetchSWR<PaginatedResponse<User>>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


export const useAnotherUser = (id?: string) => {
    return useSWR(!id ? undefined :
            `/userWithSub/${id}`,
        (url: any) => fetchSWR<User>({input: url}), {
            revalidateOnFocus: false
        }
    );
}

export const useIsUnboarded = () => {
    return useSWR('/myOnboarding', (url: any) => fetchSWR<{ isCompleted: boolean }>({input: url}), {
        revalidateOnFocus: false
    })
}


export const finishOnboarding = async () => {
    const response = await post({
        input: '/onboarding',
        init: {
            method: 'POST'
        }
    });
    return (await response.json()) as any;
}


export async function authHandler(initDataRaw: string) {
    const response = await post({
        input: '/api/auth',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            init_data: initDataRaw
        }
    });
    return (await response.json()) as any;
}

