import styled, {keyframes} from "styled-components";
import React from "react";
import {User} from "../api/requests/user-info";


interface Props {
    open: boolean;
    onClose: () => void;
    user?: User;
    onDel: () => void;
    isLoading?: boolean;
}

export const DeleteFriend = (props: Props) => {

    if (!props.open) {
        return null;
    }

    return (
        <Overlay>
            <Container>
                <Circle onClick={props.onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M13.1334 2.86663C12.8667 2.59996 12.4667 2.59996 12.2 2.86663L8.00002 7.06663L3.80002 2.86663C3.53335 2.59996 3.13335 2.59996 2.86669 2.86663C2.60002 3.13329 2.60002 3.53329 2.86669 3.79996L7.06669 7.99996L2.86669 12.2C2.60002 12.4666 2.60002 12.8666 2.86669 13.1333C3.00002 13.2666 3.13335 13.3333 3.33335 13.3333C3.53335 13.3333 3.66669 13.2666 3.80002 13.1333L8.00002 8.93329L12.2 13.1333C12.3334 13.2666 12.5334 13.3333 12.6667 13.3333C12.8 13.3333 13 13.2666 13.1334 13.1333C13.4 12.8666 13.4 12.4666 13.1334 12.2L8.93335 7.99996L13.1334 3.79996C13.4 3.53329 13.4 3.13329 13.1334 2.86663Z"
                            fill="#8290AA"/>
                    </svg>
                </Circle>
                <Top>
                    {props?.user?.photoURL && <Avatar src={props.user.photoURL}/>}
                    {!props?.user?.photoURL && <Avatar src={'/images/avatar.png'}/>}
                    <Title>
                        Удалить друга?
                    </Title>
                    <Description>
                        Вы можете вставить ссылку друга
                        и добавить его в ваш список друзей,
                        или отправить ему свою ссылку
                    </Description>
                </Top>
                <Bottom>
                    <PrimaryButton onClick={props.onDel}>
                        {props.isLoading ? <PrimaryLoader/> : 'Удалить'}
                    </PrimaryButton>
                    <SecondaryButton onClick={props.onClose}>
                        Отмена
                    </SecondaryButton>
                </Bottom>
            </Container>
        </Overlay>
    )
}

const Avatar = styled.img`
    width: 80px;
    height: 80px;
    min-width: 80px;
    border-radius: 80px;
    object-fit: cover;
`;


const Circle = styled.div`
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    height: 100vh;

`

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Container = styled.div`
    border-radius: 24px 24px 0px 0px;
    padding: 36px 16px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: 24px;
    position: relative;
    animation: ${fadeIn} 0.5s ease-in-out;

`

const Top = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

`

const Bottom = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
`

const Title = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`

const Description = styled.div`
    color: #7C9AB6;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

`


const PrimaryButton = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    background: #446E97;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

const SecondaryButton = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    color: #4B4B4B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

const flash = keyframes`
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
    }
    50% {
        background-color: #FFF;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }
    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
    }
`;

const PrimaryLoader = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 32px 0 #fff, -32px 0 #fff;
    position: relative;
    animation: ${flash} 0.5s ease-out infinite alternate;
    margin-top: 8px;
    margin-bottom: 8px;
`;

