import {useEffect} from "react";
import {useSWRUserInfo} from "../api/requests/user-info";
import {useUserStore} from "../store/user-strore";

export const useUser = () => {
    const {data, mutate, isLoading} = useSWRUserInfo();
    const setUser = useUserStore(state => state.setUser);

    useEffect(() => {
        if (data) {
            setUser(() => data);
        }
    }, [data, setUser]);

    return {
        user: useUserStore(state => state.user),
        isLoading,
        mutate,
        setUser
    }
}
