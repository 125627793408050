import useSWR from "swr";
import {fetchSWR, post, trash} from "../apiClient";
import {Comment, Subscription} from "./model";
import {useUserStore} from "../../store/user-strore";


interface CommentParams {
    limit?: number;
    skip?: boolean;
    userID?: string;
}

export const useSWRSubscriptions = (params?: CommentParams) => {
    const {skip} = params || {};
    const queryParams = new URLSearchParams();
    const userID = useUserStore().user?.id;

    if (params?.limit !== undefined) {
        queryParams.append('limit', params.limit.toString());
    } else {
        queryParams.append('limit', '100');
    }

    queryParams.append('fromUserID', userID || '');

    return useSWR((skip || !userID) ? undefined :
            `/subscription?${queryParams.toString()}`,
        (url: any) => fetchSWR<{
            items: Subscription[]
        }>({input: url}), {
            errorRetryCount: 0,
            errorRetryInterval: 0,
        }
    );
}


export const useSWRSubscriptionsExtra = (params?: CommentParams) => {
    const {skip} = params || {};
    const queryParams = new URLSearchParams();

    if (params?.limit !== undefined) {
        queryParams.append('limit', params.limit.toString());
    } else {
        queryParams.append('limit', '100');
    }

    if (params?.userID) {
        queryParams.append('fromUserID', params.userID);
    }

    return useSWR((skip || !params?.userID) ? undefined :
            `/subscriptionExtra?${queryParams.toString()}`,
        (url: any) => fetchSWR<{
            items: any[]
        }>({input: url}), {
            errorRetryCount: 0,
            errorRetryInterval: 0,
        }
    );
}


export async function createSubscription(userID: string) {
    const response = await post({
        input: '/subscription',
        data: {
            toUserID: userID
        },
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    });

    return (await response.json()) as Subscription;

}


export const removeSubscription = async (id: string) => {
    return trash({
        input: `/subscription?toUserID=${id}`,
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    });
}