import {create} from 'zustand';
import {Comment} from "../api/requests/model";

type State = {
    comments: Comment[] | null;
    setComments: (comments: (prev: Comment[]) => Comment[] | null) => void;
};

export const useCommentsStore = create<State>((set) => ({
    comments: null,
    setComments: (comments) => set((state) => ({comments: comments(state.comments!!)})),
}));
