import styled from "styled-components";
import {Logo} from "./icons/logo";
import {SettingsIcon} from "./icons/settings";
import {useNavigate} from "react-router-dom";


interface Props {
    "photoURL"?: string

}

export const Header = (props: Props) => {
    const nav = useNavigate()
    return (
        <Container>
            <Row onClick={() => nav('/')}>
                <Logo/>
                <LogoText>
                    Durov Verny Steny
                </LogoText>
            </Row>
            <Row>
                {props?.photoURL && <Avatar src={props.photoURL}/>}
                {!props?.photoURL && <Avatar src={'/images/avatar.png'}/>}
                <IconWrapper

                    onClick={() => nav('/leaders')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path
                            d="M12.6809 1.64504H11.5335V1.58103C11.5335 1.07819 11.1266 0.666748 10.6192 0.666748L3.76207 0.666748C3.25919 0.666748 2.84778 1.07819 2.84778 1.58103V1.64504H1.70492C0.946052 1.64504 0.333496 2.26217 0.333496 3.01647L0.333496 3.47819C0.333496 4.96845 1.43977 6.20732 2.87976 6.41304C3.07178 8.14102 4.27864 9.56276 5.88776 10.0656L5.30264 11.5833H9.08319L8.49806 10.0656C10.1118 9.55818 11.314 8.14102 11.5061 6.41304C12.9415 6.20732 14.0523 4.96845 14.0523 3.47819V3.01647C14.0523 2.26217 13.4352 1.64504 12.6809 1.64504ZM2.84778 5.48048C1.9335 5.27018 1.24778 4.45645 1.24778 3.47819V3.01647C1.24778 2.76505 1.45347 2.55933 1.70492 2.55933H2.84778L2.84778 5.48048ZM9.29348 5.29761L8.58034 5.99702L8.74491 6.97989C8.80891 7.35933 8.41578 7.64733 8.07292 7.46904L7.19064 7.00274L6.30378 7.46904C5.97005 7.64733 5.57234 7.35933 5.63634 6.97989L5.80548 5.99702L5.09234 5.29761C4.81349 5.02788 4.9689 4.56161 5.34376 4.50676L6.33578 4.36505L6.7792 3.46904C6.94834 3.12618 7.43749 3.12618 7.60663 3.46904L8.05007 4.36505L9.03749 4.51131C9.41692 4.56619 9.56776 5.03246 9.29348 5.29761ZM13.138 3.47819C13.138 4.4519 12.4523 5.27018 11.5335 5.48048V2.55933H12.6809C12.9324 2.55933 13.138 2.76505 13.138 3.01647V3.47819Z"
                            fill="white"/>
                        <path
                            d="M11.7035 13.9258H10.8671V13.9149C10.8671 13.1323 10.2326 12.4978 9.44992 12.4978H4.93426C4.15159 12.4978 3.51713 13.1323 3.51713 13.9149V13.9258H2.68078C2.4281 13.9258 2.22363 14.1302 2.22363 14.3829C2.22363 14.6356 2.4281 14.84 2.68078 14.84H11.7035C11.9562 14.84 12.1607 14.6356 12.1607 14.3829C12.1607 14.1302 11.9562 13.9258 11.7035 13.9258Z"
                            fill="white"/>
                    </svg>
                </IconWrapper>
                <IconWrapper onClick={() => nav('/search')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M15.0405 13.626L11.5518 10.1374C12.4269 8.90756 12.8079 7.39384 12.6194 5.8963C12.4308 4.39875 11.6865 3.02671 10.5339 2.05215C9.38137 1.07758 7.9047 0.571653 6.39665 0.634642C4.8886 0.697631 3.45926 1.32494 2.39198 2.39223C1.3247 3.45951 0.697387 4.88884 0.634398 6.39689C0.571409 7.90494 1.07734 9.38161 2.0519 10.5342C3.02646 11.6867 4.39851 12.4311 5.89605 12.6196C7.3936 12.8081 8.90731 12.4271 10.1371 11.552L13.6258 15.0407C13.8134 15.2283 14.0678 15.3337 14.3331 15.3337C14.5984 15.3337 14.8529 15.2283 15.0405 15.0407C15.2281 14.8531 15.3335 14.5987 15.3335 14.3334C15.3335 14.0681 15.2281 13.8136 15.0405 13.626ZM1.9998 6.66671C1.9998 5.74373 2.2735 4.84148 2.78628 4.07405C3.29906 3.30662 4.02789 2.70848 4.88061 2.35528C5.73334 2.00207 6.67165 1.90965 7.57689 2.08972C8.48214 2.26978 9.31366 2.71424 9.9663 3.36688C10.6189 4.01953 11.0634 4.85105 11.2435 5.75629C11.4235 6.66154 11.3311 7.59985 10.9779 8.45257C10.6247 9.30529 10.0266 10.0341 9.25913 10.5469C8.4917 11.0597 7.58945 11.3334 6.66647 11.3334C5.42923 11.332 4.24306 10.8399 3.3682 9.96499C2.49333 9.09012 2.00121 7.90396 1.9998 6.66671Z"
                            fill="white"/>
                    </svg>
                </IconWrapper>
            </Row>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #51648A;
    justify-content: space-between;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
`

const LogoText = styled.h1`
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
`

const Avatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
`


const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 1px solid #6F84AD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`