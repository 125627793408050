import {useState, useRef, useEffect} from "react";
import {ReactSketchCanvas} from "react-sketch-canvas";
import {SketchPicker} from 'react-color';
import styled, {keyframes} from "styled-components";
//@ts-ignore
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    isLoading?: boolean;
    onComment?: (base64: string) => Promise<void>;
}

export const Sketch = (props: Props) => {
    const [strokeColor, setStrokeColor] = useState("#a855f7");
    const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const [isBgColorPickerVisible, setIsBgColorPickerVisible] = useState(false);

    const [strokeWidth, setStrokeWidth] = useState(15);
    const canvasRef = useRef(null);

    const pickerRef = useRef(null);
    const pickerBgRef = useRef(null);


    const onCreateComment = async () => {

        if (props.onComment) {
            //@ts-ignore
            const imageData = await canvasRef?.current?.exportImage("png");
            await props.onComment(imageData ? imageData?.replace("data:image/png;base64,", "") : "");

            // Reset the canvas only after the graffiti is successfully sent
            //@ts-ignore
            canvasRef?.current?.resetCanvas();
        }

    }


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setIsColorPickerVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (pickerBgRef.current && !pickerBgRef.current.contains(event.target)) {
                setIsBgColorPickerVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerBgRef]);


    if (!props.isOpen) {
        return null
    }

    return (
        <Overlay>
            <Container>
                <Circle onClick={props.onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M13.1334 2.86663C12.8667 2.59996 12.4667 2.59996 12.2 2.86663L8.00002 7.06663L3.80002 2.86663C3.53335 2.59996 3.13335 2.59996 2.86669 2.86663C2.60002 3.13329 2.60002 3.53329 2.86669 3.79996L7.06669 7.99996L2.86669 12.2C2.60002 12.4666 2.60002 12.8666 2.86669 13.1333C3.00002 13.2666 3.13335 13.3333 3.33335 13.3333C3.53335 13.3333 3.66669 13.2666 3.80002 13.1333L8.00002 8.93329L12.2 13.1333C12.3334 13.2666 12.5334 13.3333 12.6667 13.3333C12.8 13.3333 13 13.2666 13.1334 13.1333C13.4 12.8666 13.4 12.4666 13.1334 12.2L8.93335 7.99996L13.1334 3.79996C13.4 3.53329 13.4 3.13329 13.1334 2.86663Z"
                            fill="#8290AA"/>
                    </svg>
                </Circle>
                <Circle2
                    onClick={() => {
                        //@ts-ignore
                        canvasRef?.current?.undo()
                    }}
                >
                    <Undo/>
                </Circle2>

                <TopColumn>
                    <Title>
                        Ваше граффити
                    </Title>

                    <ReactSketchCanvas
                        ref={canvasRef}
                        width="100%"
                        style={{
                            border: "none",
                        }}
                        strokeWidth={strokeWidth}
                        strokeColor={strokeColor}
                        canvasColor={backgroundColor}
                    />

                    <ActionColumn>
                        <ActionContainer>
                            <ActionTitle>Толщина:</ActionTitle>
                            <Range
                                className="single-thumb"
                                defaultValue={[0, 15]}
                                onInput={(value: any) => setStrokeWidth(value[1])}
                                thumbsDisabled={[true, false]}
                                rangeSlideDisabled={true}
                            />
                        </ActionContainer>

                        <ActionRow>
                            <ActionContainer>
                                <ActionTitle>Цвет линии:</ActionTitle>
                                <Color color={strokeColor}
                                       onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}/>
                            </ActionContainer>
                            <ActionContainer>
                                <ActionTitle>Цвет фона:</ActionTitle>
                                <Color color={backgroundColor}
                                       onClick={() => setIsBgColorPickerVisible(!isColorPickerVisible)}/>
                            </ActionContainer>
                        </ActionRow>
                    </ActionColumn>
                </TopColumn>
                <ButtonsRow>
                    <SecondaryButton onClick={props.onClose}>
                        Отменить
                    </SecondaryButton>
                    <PrimaryButton onClick={onCreateComment}>
                        {props?.isLoading ? <PrimaryLoader/> : "Опубликовать"}
                    </PrimaryButton>
                </ButtonsRow>
            </Container>
            {isColorPickerVisible && (
                <Absolute ref={pickerRef}>
                    <SketchPicker
                        onChange={(color) => setStrokeColor(color.hex)}
                    />
                </Absolute>

            )}
            {isBgColorPickerVisible && (
                <Absolute ref={pickerBgRef}>
                    <SketchPicker
                        onChange={(color) => setBackgroundColor(color.hex)}
                    />
                </Absolute>

            )}
        </Overlay>
    );
};


const flash = keyframes`
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
    }
    50% {
        background-color: #FFF;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }
    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
    }
`;

const PrimaryLoader = styled.span`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 32px 0 #fff, -32px 0 #fff;
    position: relative;
    animation: ${flash} 0.5s ease-out infinite alternate;
    margin-top: 8px;
    margin-bottom: 8px;
`;


const TopColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
`

const Range = styled(RangeSlider)`
    width: 200px;

    .range-slider__thumb:first-of-type {
        width: 0;

    }

    .range-slider__thumb {
        background: #446E97;

    }

    .range-slider__range {
        border-radius: 9px;
        background: #446E97;

    }
`;


const ActionTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
`


const ActionContainer = styled.label`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    box-sizing: border-box;

`

const ActionColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const ActionRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const Title = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`


const Circle = styled.div`
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

`

const Circle2 = styled.div`
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE;
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;

`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    height: 100vh;
`


const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    border-radius: 24px 24px 0px 0px;
    padding: 24px 0px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    height: 96%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 16px;
    position: relative;
    animation: ${fadeIn} 0.5s ease-in-out;
    justify-content: space-between;
 `
const Color = styled.div<{ color: string }>`
    width: 100%;
    height: 24px;
    border-radius: 2px;
    background-color: ${props => props.color};
    cursor: pointer;
    border: 1px solid #EEE;
`;


const Absolute = styled.div`
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

`

const SecondaryButton = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: #51648A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    cursor: pointer;
    width: 100%;
`

const PrimaryButton = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 8px;
    background: #51648A;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
`


const ButtonsRow = styled.div`
    display: flex;
    gap: 8px;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
`


const Undo = () => (
    <svg
        style={{
            transform: "rotate(386deg)",
            fill: '#8290AA'
        }}
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="20px"
        viewBox="0 0 24 24" version="1.1">
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Arrow" transform="translate(-480.000000, 0.000000)" fill-rule="nonzero">
                <g id="back_2_line" transform="translate(480.000000, 0.000000)">
                    <path
                        d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                        id="MingCute" fill-rule="nonzero">

                    </path>
                    <path
                        d="M6.04599,11.6767 C7.35323,9.47493 9.75524,8 12.5,8 C16.6421,8 20,11.3579 20,15.5 C20,16.0523 20.4477,16.5 21,16.5 C21.5523,16.5 22,16.0523 22,15.5 C22,10.2533 17.7467,6 12.5,6 C9.31864,6 6.50386,7.56337 4.78,9.96279 L4.24303,6.91751 C4.14713,6.37361 3.62847,6.01044 3.08458,6.10635 C2.54068,6.20225 2.17751,6.72091 2.27342,7.2648 L3.31531,13.1736 C3.36136,13.4348 3.50928,13.667 3.72654,13.8192 C4.0104,14.0179 4.38776,14.0542 4.70227,13.9445 L10.3826,12.9429 C10.9265,12.847 11.2897,12.3284 11.1938,11.7845 C11.0979,11.2406 10.5792,10.8774 10.0353,10.9733 L6.04599,11.6767 Z"
                        id="w" fill="#8290AA">

                    </path>
                </g>
            </g>
        </g>
    </svg>
)
