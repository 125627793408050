import styled from "styled-components";


interface Props {
    total?: number;
}

const getTextByCount = (count: number) => {
    if (count === 1) {
        return '1 запись'
    }
    if (count > 1 && count < 5) {
        return `${count} записи`
    }
    return `${count} записей`
}

export const WallHeader = ({total}: Props) => {
    return (
        <Container>
            <Title>
                Стена
            </Title>
            <Count>
                {getTextByCount(total || 0)}
            </Count>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #FFF;
    justify-content: space-between;
    border-top: 1px solid #EEE;


`

const Title = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`

const Count = styled.div`
    color: #9BA7B2;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

`
