import styled, {keyframes} from "styled-components";
import {useUserStore} from "../store/user-strore";
import {useRef} from "react";
import {toast, ToastContainer} from "react-toastify";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (link: string) => void;
    isLoading?: boolean;
}


export const AddFriend = (props: Props) => {
    const {user} = useUserStore();
    const inputRef = useRef<HTMLInputElement>(null);

    if (!props.isOpen) {
        return null
    }

    return (
        <Overlay>
            <Container>
                <Circle onClick={props.onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M13.1334 2.86663C12.8667 2.59996 12.4667 2.59996 12.2 2.86663L8.00002 7.06663L3.80002 2.86663C3.53335 2.59996 3.13335 2.59996 2.86669 2.86663C2.60002 3.13329 2.60002 3.53329 2.86669 3.79996L7.06669 7.99996L2.86669 12.2C2.60002 12.4666 2.60002 12.8666 2.86669 13.1333C3.00002 13.2666 3.13335 13.3333 3.33335 13.3333C3.53335 13.3333 3.66669 13.2666 3.80002 13.1333L8.00002 8.93329L12.2 13.1333C12.3334 13.2666 12.5334 13.3333 12.6667 13.3333C12.8 13.3333 13 13.2666 13.1334 13.1333C13.4 12.8666 13.4 12.4666 13.1334 12.2L8.93335 7.99996L13.1334 3.79996C13.4 3.53329 13.4 3.13329 13.1334 2.86663Z"
                            fill="#8290AA"/>
                    </svg>
                </Circle>
                <Top>
                    <Title>
                        Добавить друга
                    </Title>
                    <Description>
                        Вы можете вставить ссылку друга
                        и добавить его в ваш список друзей,
                        или отправить ему свою ссылку
                    </Description>
                </Top>
                <Bottom>
                    <AddColumn>
                        <Input
                            ref={inputRef}
                            placeholder="Введите ссылку друга"/>
                        <PrimaryButton onClick={() => {
                            if (inputRef.current) {
                                props.onAdd(inputRef.current.value);
                            }
                        }}>
                            Добавить
                        </PrimaryButton>
                    </AddColumn>
                    <Divider/>
                    <ToastContainer/>

                    <SecondaryButton
                        onClick={() => {
                            let link = 'https://t.me/durov_verny_steny_bot/wall?startapp=user' + user?.id;
                            navigator.clipboard.writeText(link);
                            toast.info('Ссылка скопирована')
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_8_3264)">
                                <path
                                    d="M14.6667 4.66675H12.6667V12.0001C12.6667 12.3681 12.368 12.6667 12 12.6667H4.66666V14.6667C4.66666 15.0347 4.96532 15.3334 5.33332 15.3334H14.6667C15.0347 15.3334 15.3333 15.0347 15.3333 14.6667V5.33341C15.3333 4.96541 15.0347 4.66675 14.6667 4.66675Z"
                                    fill="#7485A3"/>
                                <path
                                    d="M10.6667 11.3334H1.33332C0.964657 11.3334 0.666656 11.0354 0.666656 10.6667V1.33341C0.666656 0.965415 0.964657 0.666748 1.33332 0.666748H10.6667C11.0353 0.666748 11.3333 0.965415 11.3333 1.33341V10.6667C11.3333 11.0354 11.0353 11.3334 10.6667 11.3334Z"
                                    fill="#7485A3"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_8_3264">
                                    <rect width="16" height="16" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        Скопировать вашу ссылку
                    </SecondaryButton>
                </Bottom>
            </Container>
        </Overlay>
    )
}

const Circle = styled.div`
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    height: 100vh;

`

const flash = keyframes`
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
    }
    50% {
        background-color: #FFF;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }
    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    border-radius: 24px 24px 0px 0px;
    padding: 36px 16px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    height: 404px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: 24px;
    position: relative;
    animation: ${fadeIn} 0.5s ease-in-out;
`

const Top = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

`

const Bottom = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const AddColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const Title = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`

const Description = styled.div`
    color: #A0B2BF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

`

const Input = styled.input`
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;

    &::placeholder {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #999;
    }
`


const PrimaryButton = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 8px;
    background: #51648A;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
`


const Divider = () => {
    return (
        <DividerContainer>
            <Line/>
            или
            <Line/>
        </DividerContainer>
    )
}

const DividerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #999;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    justify-content: center;
`
const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #EEE;`


const SecondaryButton = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: #51648A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    cursor: pointer;
`