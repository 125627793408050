import styled from "styled-components";
import {Link} from "react-router-dom";
import {useUserStore} from "../store/user-strore";
import {toast, ToastContainer} from "react-toastify";


const truncate = (str: string, n: number) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}

export const LinkHeader = () => {
    const {user} = useUserStore()
    return (
        <Container
            className={'link-header'}
        >
            <SLink

                to={'https://t.me/share/url?url=t.me/durov_verny_steny_bot/wall?startapp=user' + user?.id}>
                {truncate('https://t.me/durov_verny_steny_bot/wall?startapp=useer' + user?.id, 30)}
            </SLink>
            <ToastContainer/>
            <svg onClick={() => {
                let link = 'https://t.me/durov_verny_steny_bot/wall?startapp=user' + user?.id;
                navigator.clipboard.writeText(link);
                toast.info('Ссылка скопирована')
            }}
                 xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_32_263)">
                    <path
                        d="M14.6667 4.66667H12.6667V12C12.6667 12.368 12.368 12.6667 12 12.6667H4.66667V14.6667C4.66667 15.0347 4.96534 15.3333 5.33334 15.3333H14.6667C15.0347 15.3333 15.3333 15.0347 15.3333 14.6667V5.33334C15.3333 4.96534 15.0347 4.66667 14.6667 4.66667Z"
                        fill="#7485A3"/>
                    <path
                        d="M10.6667 11.3333H1.33334C0.964672 11.3333 0.666672 11.0353 0.666672 10.6667V1.33334C0.666672 0.965338 0.964672 0.666672 1.33334 0.666672H10.6667C11.0353 0.666672 11.3333 0.965338 11.3333 1.33334V10.6667C11.3333 11.0353 11.0353 11.3333 10.6667 11.3333Z"
                        fill="#7485A3"/>
                </g>
                <defs>
                    <clipPath id="clip0_32_263">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch;
    background: #303D56;
`


const SLink = styled(Link)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex-shrink: 0;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-decoration: none;

`