import {User} from "../api/requests/user-info";

interface Props{

    cutTo: number
    user?: User
}

export const getTgName = (props: Props) => {
    const {cutTo, user} = props

    const name = user?.username || user?.name || user?.telegramID || 'Unknown'


    if(name.length > cutTo){
        return name.slice(0, cutTo) + '...'
    }

    return name

}