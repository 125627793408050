import styled, {keyframes} from "styled-components";
import {User} from "../api/requests/user-info";
import React from "react";
import {getTgName} from "../ustils/getTgName";
import {Loader} from "./loader";
import {useNavigate} from "react-router-dom";

interface Props {
    user?: User;
    onAddFriend: (uid: string) => void;
    onRemoveFriend: (uid: string) => void;
    isLoading?: boolean;
    className?: string;
}


const getFriendsText = (count: number) => {
    if (count === 1) {
        return '1 друг'
    }
    if (count > 1 && count < 5) {
        return `${count} друга`
    }
    return `${count} друзей`
}

export const ProfileHeader = ({user, onAddFriend, onRemoveFriend, isLoading, className}: Props) => {
    const nav = useNavigate()

    if (!user) {
        return <Container style={{height: '100px'}}/>
    }
    return (
        <Container
        className={'profile'}
        >
            <Row>
                {user?.photoURL && <Avatar src={user?.photoURL}/>}
                {!user?.photoURL && <Avatar src={'/images/avatar.png'}/>}
                <Col>
                    <UserName>
                        {getTgName({
                            user: user,
                            cutTo: 24
                        })}
                    </UserName>
                    <FriendsCount
                        onClick={() => nav(`/friends/${user?.id}`)}
                    >
                        {getFriendsText(user?.subscriptionCount || 0)}
                    </FriendsCount>
                </Col>
            </Row>
            {!user?.isSubscribed ?
                <PrimaryButton onClick={() => {
                    if (user?.id) {
                        onAddFriend(user.id)
                    }
                }}>
                    {isLoading ? <PrimaryLoader/> : 'Добавить в друзья'}
                </PrimaryButton>
                :
                <SecondaryButton onClick={() => {
                    if (user?.id) {
                        onRemoveFriend(user.id)
                    }
                }}>
                    {isLoading ? <Loader/> : 'Удалить друга'}
                </SecondaryButton>
            }
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    align-self: stretch;
    box-sizing: border-box;
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;

`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

`


const Avatar = styled.img`
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50px;
    object-fit: cover;
`;

const UserName = styled.div`
    color: #1F1F1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

const FriendsCount = styled.div`
    color: #446E97;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
`

const PrimaryButton = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    background: #446E97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    color: white;
    margin: 0 0 0 auto;
    box-sizing: border-box;
    cursor: pointer;

`


const SecondaryButton = styled.div`
    cursor: pointer;
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    color: #4B4B4B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

const flash = keyframes`
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
    }
    50% {
        background-color: #FFF;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }
    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
    }
`;

const PrimaryLoader = styled.span`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 32px 0 #fff, -32px 0 #fff;
    position: relative;
    animation: ${flash} 0.5s ease-out infinite alternate;
    margin-top: 8px;
    margin-bottom: 8px;
`;

