import styled from "styled-components";

export const EmptyFriends = ({isAnotherUser}: { isAnotherUser?: boolean }) => {
    return (
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
                <path
                    d="M32.5 35.6C20.9 35.6 11.4333 42.9333 11.4333 54.5333C11.4333 66.1333 53.5667 66.1333 53.5667 54.5333C53.5667 42.9333 44.1 35.6 32.5 35.6Z"
                    fill="#D6DADE"/>
                <path
                    d="M19.7 23.3333C19.7 21.4666 20.1 19.7333 20.7667 18.1333C19.5667 17.8666 18.2333 17.7333 16.7667 17.7333C8.1 17.7333 1.03333 22.6666 1.03333 31.1999C1.03333 35.5999 8.9 37.7333 16.7667 37.7333C19.1667 35.9999 21.9667 34.5333 25.0333 33.7333C21.8333 31.5999 19.7 27.7333 19.7 23.3333Z"
                    fill="#D6DADE"/>
                <path
                    d="M63.8333 31.3332C63.8333 22.6666 56.7667 17.8666 48.1 17.8666C46.7667 17.8666 45.4333 17.9999 44.1 18.2666C44.7667 19.8666 45.1667 21.5999 45.1667 23.4666C45.1667 27.8666 43.0333 31.7332 39.7 33.9999C42.7667 34.7999 45.5667 36.1332 47.9667 37.9999C55.9667 37.8666 63.8333 35.7332 63.8333 31.3332Z"
                    fill="#D6DADE"/>
                <path
                    d="M32.5 33.4666C38.0965 33.4666 42.6333 28.9298 42.6333 23.3333C42.6333 17.7368 38.0965 13.2 32.5 13.2C26.9035 13.2 22.3667 17.7368 22.3667 23.3333C22.3667 28.9298 26.9035 33.4666 32.5 33.4666Z"
                    fill="#D6DADE"/>
                <path
                    d="M9.3 8.13329C9.3 12.2666 12.6333 15.6 16.7667 15.6C20.9 15.6 24.2333 12.2666 24.2333 8.13329C24.2333 3.99996 20.9 0.666626 16.7667 0.666626C12.6333 0.666626 9.3 3.99996 9.3 8.13329Z"
                    fill="#D6DADE"/>
                <path
                    d="M40.6333 8.13329C40.6333 12.2666 43.9667 15.6 48.1 15.6C52.2333 15.6 55.5667 12.2666 55.5667 8.13329C55.5667 3.99996 52.2333 0.666626 48.1 0.666626C43.9667 0.666626 40.6333 3.99996 40.6333 8.13329Z"
                    fill="#D6DADE"/>
            </svg>
            <Title>
                 {isAnotherUser ? 'У пользователя нет друзей' : 'У вас нет друзей'}
            </Title>
            <Description>
                     {isAnotherUser ? 'Поделитесь ссылкой или добавьте вашего первого друга' : 'Добавьте пользователя в друзья'}
            </Description>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;


`

const Title = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;  
`


const Description = styled.div`
    color: #A0B2BF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`