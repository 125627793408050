import {create} from 'zustand';

type State = {
    step: number;
    setStep: (step: (prev: number) => number) => void;
    redirectTo: string |null;
    setRedirectTo: (redirectTo: (prev: string) => string) => void;
};

export const useStepsStore = create<State>((set) => ({
    step: 0,
    setStep: (step) => set((state) => ({step: step(state.step!!)})),
    redirectTo: null,
    setRedirectTo: (redirectTo) => set((state) => ({redirectTo: redirectTo(state.redirectTo!!)})),
}));
