import {useEffect} from "react";
import {postEvent} from "@telegram-apps/sdk-react";

export const useTgSetUp = () => {
    useEffect(() => {
        try {
            postEvent('web_app_expand')
            postEvent('web_app_set_header_color', {color: "#51648A"});
            postEvent('web_app_set_background_color', {color: "#FFFFFF"});
            postEvent('web_app_setup_swipe_behavior', {allow_vertical_swipe: false});
            postEvent('web_app_setup_closing_behavior', {need_confirmation: true});
            postEvent('web_app_setup_back_button', {is_visible: true});
        } catch (e) {
        }
    }, []);
}