import React from "react";

export const Logo = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.93663 0H20.0634C22.2274 0 24 1.7726 24 3.93663V20.0634C24 22.2274 22.2274 24 20.0634 24H3.93663C1.7726 24 0 22.2274 0 20.0634V3.93663C0 1.7726 1.7726 0 3.93663 0Z"
                  fill="white"/>
            <path
                d="M11.8477 17H8V7H11.8086C12.8372 7 13.7259 7.2002 14.4746 7.60059C15.2266 7.99772 15.806 8.57064 16.2129 9.31934C16.623 10.0648 16.8281 10.9583 16.8281 12C16.8281 13.0417 16.6247 13.9368 16.2178 14.6855C15.8109 15.431 15.2347 16.0039 14.4893 16.4043C13.7438 16.8014 12.8633 17 11.8477 17ZM10.7148 14.6953H11.75C12.2448 14.6953 12.6663 14.6156 13.0146 14.4561C13.3662 14.2965 13.6331 14.0215 13.8154 13.6309C14.001 13.2402 14.0937 12.6966 14.0937 12C14.0937 11.3034 13.9993 10.7598 13.8105 10.3691C13.625 9.97852 13.3516 9.70345 12.9902 9.54395C12.6322 9.38444 12.1927 9.30469 11.6719 9.30469H10.7148V14.6953Z"
                fill="#51648A"/>
        </svg>
    )
}