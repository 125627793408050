import styled from "styled-components";
import {Header} from "../header";
import React, {useEffect, useState} from "react";
import {useUser} from "../../hooks/use-user";
import {getTgName} from "../../ustils/getTgName";
import {useNavigate} from "react-router-dom";
import {User, useUsers} from "../../api/requests/user-info";
import {Loader} from "../loader";
import {createSubscription, removeSubscription} from "../../api/requests/subscriptions";
import {DeleteFriend} from "../delete-modal";
import {AddFriend} from "../add-friend2";
import {postEvent} from "@telegram-apps/sdk-react";


const initTabs = [
    {
        name: 'Посты',
        isActive: true,
        order: 'wall_comments_count'
    },
    {
        name: 'Подписчики',
        isActive: false,
        order: 'subscribers_count'
    },
    {
        name: 'Подписки',
        isActive: false,
        order: 'subscription_count'
    }
]

const getInfoText = (order: string, count: number) => {
    switch (order) {
        case 'wall_comments_count':
            return count === 1 ? ' пост' : count > 1 && count < 5 ? ' поста' : ' постов'
        case 'subscribers_count':
            return count === 1 ? ' подписчик' : count > 1 && count < 5 ? ' подписчика' : ' подписчиков'
        case 'subscription_count':
            return count === 1 ? ' подписка' : count > 1 && count < 5 ? ' подписки' : ' подписок'
        default:
            return ' подписок'
    }
}


const getFields = (order: string) => {
    switch (order) {
        case 'wall_comments_count':
            return 'wallCommentsCount'
        case 'subscribers_count':
            return 'subscribersCount'
        case 'subscription_count':
            return 'subscriptionCount'
        default:
            return 'subscriptionCount'
    }
}

export const Leaders = () => {
    const navigate = useNavigate()
    const [tabs, setTabs] = useState(initTabs)

    const [userToDelete, setUserToDelete] = React.useState<User | null>(null);
    const [isActionLoading, setIsActionLoading] = React.useState(false);
    const [userToAdd, setUserToAdd] = React.useState<User | null>(null);
    const {user} = useUser()
    const {data: users, isLoading, mutate} = useUsers({
        orderBy: tabs.find((tab) => tab.isActive)?.order + '.desc',
        limit: 300
    })


    const onNavigate = (id: string) => {
        if (user?.id === id) {
            navigate(`/`)
        } else {
            navigate(`/user/${id}`)
        }
    }

    const onDelete = async () => {
        setIsActionLoading(true)
        try {
            await removeSubscription(userToDelete?.id || '')
            await mutate()
        } catch (e) {
            console.log(e)
            setUserToDelete(null)
        } finally {
            setUserToDelete(null)
            setIsActionLoading(false)
        }

    }

    const onAdd = async () => {
        setIsActionLoading(true)
        try {
            await createSubscription(userToAdd?.id || '')
            await mutate()
        } catch (e) {
            console.log(e)
            setUserToAdd(null)
        } finally {
            setUserToAdd(null)
            setIsActionLoading(false)
        }

    }

    useEffect(() => {
        postEvent('web_app_setup_back_button', {is_visible: true});
    }, []);

    if (isLoading) {
        return <Loader isFullPage/>
    }

    return (
        <Container>
            <Header photoURL={user?.photoURL}/>
            <Tabs>
                {tabs.map((tab) => (
                    <Tab
                        onClick={() => setTabs(tabs.map((t) => ({...t, isActive: t.name === tab.name})))}
                        isActive={tab.isActive}>
                        {tab.name}
                    </Tab>
                ))}
            </Tabs>
            <ListHeader>
                <Title>
                    Список лидеров
                </Title>
            </ListHeader>

            {users?.items?.map((item) => (
                <ItemWrapper>
                    <Info>
                        {item?.photoURL && <Avatar
                            onClick={() => onNavigate(item.id)}
                            src={item.photoURL}/>}
                        {!item?.photoURL && <Avatar
                            onClick={() => onNavigate(item.id)}
                            src={'/images/avatar.png'}/>}
                        <InfoCol>
                            <UserName
                                onClick={() => onNavigate(item.id)}
                            >
                                {getTgName({
                                    user: item,
                                    cutTo: 28
                                })}
                            </UserName>
                            <InfoText>
                                {item[getFields(tabs.find((tab) => tab.isActive)?.order || '')]}
                                {getInfoText(tabs.find((tab) => tab.isActive)?.order || '', item[getFields(tabs.find((tab) => tab.isActive)?.order || '')] || 0)}
                            </InfoText>

                        </InfoCol>
                    </Info>

                    {item.id !== user?.id &&
                        <>
                            {!item.isSubscribed && (
                                <ButtonContainer onClick={() => setUserToAdd(item)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <g clip-path="url(#clip0_184_4589)">
                                            <path
                                                d="M6.66667 7.33333C8.69171 7.33333 10.3333 5.69171 10.3333 3.66667C10.3333 1.64162 8.69171 0 6.66667 0C4.64162 0 3 1.64162 3 3.66667C3 5.69171 4.64162 7.33333 6.66667 7.33333Z"
                                                fill="#A1B6CB"/>
                                            <path
                                                d="M6.66667 12.0001C6.66667 10.7834 7.08067 9.66408 7.76933 8.76608C7.41 8.70608 7.04333 8.66675 6.66667 8.66675C2.99067 8.66675 0 11.6574 0 15.3334C0 15.7021 0.298667 16.0001 0.666667 16.0001H8.484C7.37267 15.0221 6.66667 13.5934 6.66667 12.0001Z"
                                                fill="#A1B6CB"/>
                                            <path
                                                d="M12 8C9.79067 8 8 9.79067 8 12C8 14.2093 9.79067 16 12 16C14.2093 16 16 14.2093 16 12C16 9.79067 14.2093 8 12 8ZM14.3333 12.6667H12.6667V14.3333C12.6667 14.5173 12.5173 14.6667 12.3333 14.6667H11.6667C11.4827 14.6667 11.3333 14.5173 11.3333 14.3333V12.6667H9.66667C9.48267 12.6667 9.33333 12.5173 9.33333 12.3333V11.6667C9.33333 11.4827 9.48267 11.3333 9.66667 11.3333H11.3333V9.66667C11.3333 9.48267 11.4827 9.33333 11.6667 9.33333H12.3333C12.5173 9.33333 12.6667 9.48267 12.6667 9.66667V11.3333H14.3333C14.5173 11.3333 14.6667 11.4827 14.6667 11.6667V12.3333C14.6667 12.5173 14.5173 12.6667 14.3333 12.6667Z"
                                                fill="#A1B6CB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_184_4589">
                                                <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </ButtonContainer>
                            )}
                            {item.isSubscribed && (
                                <ButtonContainer onClick={() => setUserToDelete(item)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <g clip-path="url(#clip0_129_1438)">
                                            <path
                                                d="M6.66667 7.33333C8.69171 7.33333 10.3333 5.69171 10.3333 3.66667C10.3333 1.64162 8.69171 0 6.66667 0C4.64162 0 3 1.64162 3 3.66667C3 5.69171 4.64162 7.33333 6.66667 7.33333Z"
                                                fill="#A1B6CB"/>
                                            <path
                                                d="M6.66667 12C6.66667 10.7833 7.08067 9.66396 7.76933 8.76596C7.41 8.70596 7.04333 8.66663 6.66667 8.66663C2.99067 8.66663 0 11.6573 0 15.3333C0 15.702 0.298667 16 0.666667 16H8.484C7.37267 15.022 6.66667 13.5933 6.66667 12Z"
                                                fill="#A1B6CB"/>
                                            <path
                                                d="M12 8C9.79067 8 8 9.79067 8 12C8 14.2093 9.79067 16 12 16C14.2093 16 16 14.2093 16 12C16 9.79067 14.2093 8 12 8ZM14.3333 12.6667H9.66667C9.48267 12.6667 9.33333 12.5173 9.33333 12.3333V11.6667C9.33333 11.4827 9.48267 11.3333 9.66667 11.3333H14.3333C14.5173 11.3333 14.6667 11.4827 14.6667 11.6667V12.3333C14.6667 12.5173 14.5173 12.6667 14.3333 12.6667Z"
                                                fill="#A1B6CB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_129_1438">
                                                <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </ButtonContainer>
                            )}
                        </>
                    }
                </ItemWrapper>
            ))}

            <DeleteFriend
                isLoading={isActionLoading}
                onDel={onDelete}
                open={!!userToDelete} onClose={() => setUserToDelete(null)}/>
            <AddFriend
                isLoading={isActionLoading}
                onAdd={onAdd}
                open={!!userToAdd} onClose={() => setUserToAdd(null)}/>

        </Container>
    )
}


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #ffffff;

`

const InfoText = styled.div`
    color: #A1B6CB;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`


const ButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: 1px solid #EEE;
    cursor: pointer;
`

const ItemWrapper = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #EEE;
    background: #FFF;
`

const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`
const InfoCol = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
`


const UserName = styled.div`
    color: #446E97;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
`


const Avatar = styled.img`
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 40px;
    cursor: pointer;

    object-fit: cover;
`;


const ListHeader = styled.div`
    display: flex;
    padding: 7px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
    width: 100%;
    background: #ECF1F5;

`


const Title = styled.h1`
    color: #446E97;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
`

const Found = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`

const FoundNumber = styled.span`
    color: #7C9AB6;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`


const Tabs = styled.div`
    display: flex;
    width: 100%;
    padding: 10px 16px;

    justify-content: center;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    background: #D0DBE5;
`

const Tab = styled.div<{ isActive?: boolean }>`
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 100px;
    background: ${({isActive}) => isActive ? '#FFF' : 'transparent'};
    cursor: pointer;
    box-sizing: border-box;
    border: ${({isActive}) => !isActive ? '1px solid #B4C5D5' : 'none'};
    color: #2E5070;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
`
