import styled from "styled-components";
import {Header} from "../header";
import {FriendsHeader} from "../friends-header";
import {EmptyFriends} from "../empty-friends";
import {WallHeader} from "../wall-header";
import {MainInput} from "../main-input";
import {AddFriend} from "../add-friend";
import {EmptyPosts} from "../empty-posts";
import {LinkHeader} from "../link-header";
import {
    createComment,
    editComment,
    removeComment,
    useTotalComments
} from "../../api/requests/comment";
import {useEffect, useState} from "react";
import {Comment} from "../comment";
import {useComments} from "../../hooks/use-comments";
import {createSubscription, removeSubscription, useSWRSubscriptions} from "../../api/requests/subscriptions";
import {Subscriptions} from "../subscription";
import {useUser} from "../../hooks/use-user";
import {useTgSetUp} from "../../hooks/useTgSetUp";
import {useParams} from "react-router-dom";
import {ProfileHeader} from "../profile-header";
import {useAnotherUser} from "../../api/requests/user-info";
import {Loader} from "../loader";
import {Sketch} from "../sketch";
import {postEvent} from "@telegram-apps/sdk-react";

interface Props {
    isAnotherUser: boolean | undefined;
}

export const UserView = (props: Props) => {
    const [isAnotherUser, setIsAnotherUser] = useState(props.isAnotherUser);
    const [isSending, setIsSending] = useState(false);
    const [isFriendActionLoading, setIsFriendActionLoading] = useState(false);
    const [isAddFriendModalOpen, setIsAddFriendModalOpen] = useState(false);
    const [isGraphityModalOpen, setIsGraphityModalOpen] = useState(false);

    useEffect(() => {
        setIsAnotherUser(props.isAnotherUser)
    }, [props.isAnotherUser])


    useTgSetUp()
    const {id} = useParams();

    const {data: otherUser, isLoading: isOtherUserLoading, mutate: mutateAnotherUser, error} = useAnotherUser(id)
    const {user} = useUser()

    const {comments, isLoading: isCommentsLoading, setComments} = useComments({
        userID: isAnotherUser ? id : user?.id
    })
    const {data: subscriptions, isLoading: isSubscriptionsLoading, mutate: mutateSubscriptions} = useSWRSubscriptions();
    const {data: totalComments, mutate: updateTotal} = useTotalComments({
        userID: isAnotherUser ? id : user?.id
    });

    useEffect(() => {
        if (id === user?.id) {
            setIsAnotherUser(false)
        }
    }, [id, user?.id])


    const onDeleteComment = (id: string) => {
        removeComment(id).then()
        setComments((comments) => comments?.filter((comment) => comment.id !== id))
        setComments((comments) => comments?.map((comment) => ({
            ...comment,
            lastChildren: comment.lastChildren.filter((child) => child.id !== id)
        })))
    }
    const onCreateComment = async (text: string) => {
        setIsSending(true)
        try {
            const resp = await createComment({
                text: text,
                userID: isAnotherUser ? id : user?.id
            });

            if (resp) {
                setComments((comments) => [resp, ...comments])
                await updateTotal()
            }


        } catch (e) {
            console.log(e)
        } finally {
            setIsSending(false)
        }

    }


    const onEditComment = async (id: string, text: string) => {
        try {
            setComments((comments) => comments?.map((comment) => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        text: text
                    }
                }
                return comment
            }))

            const resp = await editComment({
                text: text,
                id: id
            });


        } catch (e) {
            setComments((comments) => comments?.map((comment) => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        text: text
                    }
                }
                return comment
            }))
        } finally {
            setIsSending(false)

        }
    }

    const onAddFriend = async (id: string) => {
        setIsFriendActionLoading(true)
        try {
            await createSubscription(id)
            await mutateAnotherUser()
            await mutateSubscriptions()
        } catch (e) {
            console.log(e)
        } finally {
            setIsFriendActionLoading(false)
        }

    }

    const onAddFriensViaLink = async (text: string) => {
        const uuidPattern = /user([a-f0-9-]{36})/;


        const match = text.match(uuidPattern);
        if (!match) {
            return
        }
        setIsFriendActionLoading(true)
        try {
            const resp = await createSubscription(match[1])
            if (resp) {
                await mutateAnotherUser()
                await mutateSubscriptions()
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsAddFriendModalOpen(false)
            setIsFriendActionLoading(false)
        }
    }


    const onCreateCommentWithGrafiti = async (grafitiBase64: string) => {
        setIsSending(true)
        try {
            const resp = await createComment({
                grafitiBase64: grafitiBase64,
                userID: isAnotherUser ? id : user?.id
            });

            if (resp) {
                setComments((comments) => [resp, ...comments])
                await updateTotal()
            }

        } catch (e) {
            console.log(e)
        } finally {
            setIsSending(false)
            setIsGraphityModalOpen(false)
        }

    }


    const onRemoveFriend = async (id: string) => {
        setIsFriendActionLoading(true)
        try {
            await removeSubscription(id)
            await mutateAnotherUser()
            await mutateSubscriptions()
        } catch (e) {
            console.log(e)
        } finally {
            setIsFriendActionLoading(false)
        }

    }

    useEffect(() => {

        postEvent('web_app_setup_back_button', {is_visible: true});
    }, []);

    if (isOtherUserLoading || isSubscriptionsLoading || !user || isCommentsLoading) {
        return <Loader isFullPage/>
    }


    return (
        <Container>
            <Header photoURL={user?.photoURL}/>
            <LinkHeader/>
            {!isAnotherUser &&
                <FriendsHeader
                    onAddFriend={() => setIsAddFriendModalOpen(true)}
                    user={isAnotherUser ? otherUser : user}/>
            }
            {(!isAnotherUser && ((subscriptions?.items?.length || 0) > 0)) &&
                <Subscriptions subscriptions={subscriptions?.items || []}/>
            }
            {isAnotherUser &&
                <ProfileHeader
                    isLoading={isFriendActionLoading}
                    onAddFriend={onAddFriend}
                    onRemoveFriend={onRemoveFriend}
                    user={otherUser}
                />
            }
            {(!isSubscriptionsLoading && subscriptions?.items.length === 0 && !isAnotherUser) &&
                <EmptyFriends/>
            }
            <WallHeader total={totalComments?.count}/>
            <MainInput
                onOpenGraphity={() => setIsGraphityModalOpen(true)}
                isLoading={isSending}
                onComment={onCreateComment}
                isAnotherUser={isAnotherUser}/>
            {(!isCommentsLoading && comments?.length === 0) &&
                <EmptyPosts isAnotherUser={isAnotherUser}/>
            }
            {comments?.map((item, index) => (
                <CommentWrapper>
                    <Comment
                        onEdit={onEditComment}
                        isPost onDelete={onDeleteComment} {...item} key={index}/>
                </CommentWrapper>
            ))}
            <KeyBoardHelper/>

            {(comments?.length && comments?.length > 0 )&&
                <KeyBoardHelper/>
            }
            <Sketch
                isLoading={isSending}
                onComment={onCreateCommentWithGrafiti}
                isOpen={isGraphityModalOpen}
                onClose={() => setIsGraphityModalOpen(false)}
            />
            <AddFriend
                isLoading={isFriendActionLoading}
                onAdd={onAddFriensViaLink}
                onClose={() => setIsAddFriendModalOpen(false)}
                isOpen={isAddFriendModalOpen}/>
        </Container>
    )
}


const KeyBoardHelper = styled.div`
    width: 100px;
    display: flex;
    margin-top: 40vh;
`


const Container = styled.div`
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;

    //&::-webkit-scrollbar {
    //    display: none; /* Safari and Chrome */
    //}
    //scrollbar-width: none; /* Firefox */
    //-ms-overflow-style: none; /* Internet Explorer 10+ */
    //
    //&::-webkit-scrollbar {
    //    display: none; /* Safari and Chrome */
    //}
    //
    //-webkit-overflow-scrolling: touch;
    //overscroll-behavior: none;
    //overflow: scroll
`


const CommentWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    border-bottom: 1px solid #EEE;

`
