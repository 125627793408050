import styled from "styled-components";
import {Link} from "react-router-dom";
import {User} from "../api/requests/user-info";


interface Props {
    user?: User | null;
    onAddFriend: () => void;
}

export const FriendsHeader = (props: Props) => {
    return (
        <Container>
            <Label to={"/friends/" + props?.user?.id}>
                Друзья
            </Label>
            <Row onClick={props.onAddFriend}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_30_285)">
                        <path
                            d="M6.66667 7.33333C8.69171 7.33333 10.3333 5.69171 10.3333 3.66667C10.3333 1.64162 8.69171 0 6.66667 0C4.64162 0 3 1.64162 3 3.66667C3 5.69171 4.64162 7.33333 6.66667 7.33333Z"
                            fill="#8390AB"/>
                        <path
                            d="M6.66667 8.66666C2.99067 8.66666 0 11.6573 0 15.3333C0 15.702 0.298667 16 0.666667 16H12.6667C13.0347 16 13.3333 15.702 13.3333 15.3333C13.3333 11.6573 10.3427 8.66666 6.66667 8.66666Z"
                            fill="#8390AB"/>
                        <path
                            d="M15.6667 6.66666H14V5C14 4.816 13.8507 4.66666 13.6667 4.66666H13C12.816 4.66666 12.6667 4.816 12.6667 5V6.66666H11C10.816 6.66666 10.6667 6.816 10.6667 7V7.66666C10.6667 7.85066 10.816 8 11 8H12.6667V9.66666C12.6667 9.85066 12.816 10 13 10H13.6667C13.8507 10 14 9.85066 14 9.66666V8H15.6667C15.8507 8 16 7.85066 16 7.66666V7C16 6.816 15.8507 6.66666 15.6667 6.66666Z"
                            fill="#8390AB"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_30_285">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                Добавить друга
            </Row>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #E0E7EC;
    justify-content: space-between;
`


const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #8390AB;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    cursor: pointer;
`


const Label = styled(Link)`
    color: #446E97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
`
