import styled from "styled-components";
import React, { useState, useRef } from "react";

export const SearchInput = ({ onSearch }: { onSearch: (value: string) => void }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        onSearch(e.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                <path
                    d="M22.5608 20.4391L17.3278 15.2061C18.6404 13.3613 19.2119 11.0908 18.9292 8.84444C18.6464 6.59813 17.5299 4.54006 15.801 3.07822C14.0722 1.61638 11.8572 0.857479 9.59509 0.951963C7.33302 1.04645 5.18902 1.98742 3.58809 3.58834C1.98717 5.18926 1.0462 7.33326 0.951719 9.59534C0.857235 11.8574 1.61613 14.0724 3.07798 15.8013C4.53982 17.5301 6.59788 18.6466 8.8442 18.9294C11.0905 19.2122 13.3611 18.6406 15.2058 17.3281L20.4388 22.5611C20.7202 22.8425 21.1019 23.0006 21.4998 23.0006C21.8978 23.0006 22.2794 22.8425 22.5608 22.5611C22.8422 22.2797 23.0003 21.898 23.0003 21.5001C23.0003 21.1021 22.8422 20.7205 22.5608 20.4391ZM2.99983 10.0001C2.99983 8.6156 3.41037 7.26222 4.17954 6.11108C4.94871 4.95993 6.04196 4.06273 7.32104 3.53291C8.60012 3.0031 10.0076 2.86448 11.3655 3.13457C12.7233 3.40467 13.9706 4.07135 14.9496 5.05032C15.9285 6.02929 16.5952 7.27657 16.8653 8.63444C17.1354 9.9923 16.9968 11.3998 16.467 12.6789C15.9372 13.9579 15.04 15.0512 13.8888 15.8204C12.7377 16.5895 11.3843 17.0001 9.99983 17.0001C8.14396 16.998 6.36471 16.2598 5.05242 14.9475C3.74012 13.6352 3.00194 11.8559 2.99983 10.0001Z"
                    fill="white"/>
            </svg>
            <Input
                 ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {!inputValue && !isFocused && (
                <Placeholder
                    onClick={() => inputRef.current?.focus()}
                >
                    Введите имя пользователя
                </Placeholder>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    padding: 16px;
 
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #446E97;
    width: 100%;
    box-sizing: border-box;
    position: relative;
`;

const Input = styled.input`
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: white;
`;

const Placeholder = styled.span`
    color: #B4C5D5;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;  
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
`;
