import styled from "styled-components";
import {Header} from "../header";
import React, {useEffect} from "react";
import {useUser} from "../../hooks/use-user";
import {FriendsHeader} from "../friends-header";
import {removeSubscription, useSWRSubscriptions, useSWRSubscriptionsExtra} from "../../api/requests/subscriptions";
import {getTgName} from "../../ustils/getTgName";
import {useNavigate, useParams} from "react-router-dom";
import {User} from "../../api/requests/user-info";
import {DeleteFriend} from "../delete-modal";
import {EmptyFriends} from "../empty-friends";
import {Loader} from "../loader";
import {postEvent} from "@telegram-apps/sdk-react";


export const UsersView = () => {
    const {id} = useParams();
    const navigate = useNavigate()

    const {user} = useUser()
    const {data: subscriptions, isLoading: isLoading1, mutate: mutate1} = useSWRSubscriptions({
        skip: user?.id !== id
    })

    const [userToDelete, setUserToDelete] = React.useState<User | null>(null);
    const [isDelLoading, setIsDelLoading] = React.useState(false);

    const {data: friendsOfFriends, isLoading, mutate} = useSWRSubscriptionsExtra({userID: id})


    const onDelete = async () => {
        setIsDelLoading(true)
        try {
            await removeSubscription(userToDelete?.id || '')
            if (user?.id !== id) {
                await mutate()
            } else {
                await mutate1()
            }
        } catch (e) {
            console.log(e)
            setUserToDelete(null)
        } finally {
            setUserToDelete(null)
            setIsDelLoading(false)
        }

    }


    const onNavigate = (id: string) => {
        if(user?.id === id) {
            navigate(`/`)
        } else {
            navigate(`/user/${id}`)
        }
    }


    useEffect(() => {

        postEvent('web_app_setup_back_button', {is_visible: true});
    }, []);

    if (isLoading1 || isLoading) {
        return <Loader isFullPage/>
    }

    return (
        <Container>
            <Header photoURL={user?.photoURL}/>
            {/*@ts-ignore*/}
            <FriendsHeader/>
            {(!subscriptions?.items?.length && !friendsOfFriends?.items?.length) &&
                <EmptyFriends isAnotherUser={user?.id !== id}/>
            }

            {(user?.id === id ? subscriptions?.items : friendsOfFriends?.items)?.map((item) => (
                <ItemWrapper>
                    <Info>
                        {item?.toUser.photoURL && <Avatar
                            onClick={() => onNavigate(item.toUser.id)}
                            src={item.toUser.photoURL}/>}
                        {!item?.toUser.photoURL && <Avatar
                            onClick={() => onNavigate(item.toUser.id)}
                            src={'/images/avatar.png'}/>}
                        <InfoCol>
                            <UserName
                                onClick={() => onNavigate(item.toUser.id)}

                            >
                                {getTgName({
                                    user: item.toUser,
                                    cutTo: 28
                                })}
                            </UserName>

                            {item.toUser.isOnline ? (
                                <Online>
                                    Онлайн
                                </Online>
                            ) : (
                                <Offline>
                                    Оффлайн
                                </Offline>
                            )}

                        </InfoCol>
                    </Info>
                    {user?.id === id && (
                        <ButtonContainer onClick={() => setUserToDelete(item.toUser)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                 fill="none">
                                <g clip-path="url(#clip0_129_1438)">
                                    <path
                                        d="M6.66667 7.33333C8.69171 7.33333 10.3333 5.69171 10.3333 3.66667C10.3333 1.64162 8.69171 0 6.66667 0C4.64162 0 3 1.64162 3 3.66667C3 5.69171 4.64162 7.33333 6.66667 7.33333Z"
                                        fill="#A1B6CB"/>
                                    <path
                                        d="M6.66667 12C6.66667 10.7833 7.08067 9.66396 7.76933 8.76596C7.41 8.70596 7.04333 8.66663 6.66667 8.66663C2.99067 8.66663 0 11.6573 0 15.3333C0 15.702 0.298667 16 0.666667 16H8.484C7.37267 15.022 6.66667 13.5933 6.66667 12Z"
                                        fill="#A1B6CB"/>
                                    <path
                                        d="M12 8C9.79067 8 8 9.79067 8 12C8 14.2093 9.79067 16 12 16C14.2093 16 16 14.2093 16 12C16 9.79067 14.2093 8 12 8ZM14.3333 12.6667H9.66667C9.48267 12.6667 9.33333 12.5173 9.33333 12.3333V11.6667C9.33333 11.4827 9.48267 11.3333 9.66667 11.3333H14.3333C14.5173 11.3333 14.6667 11.4827 14.6667 11.6667V12.3333C14.6667 12.5173 14.5173 12.6667 14.3333 12.6667Z"
                                        fill="#A1B6CB"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_129_1438">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </ButtonContainer>
                    )}
                </ItemWrapper>
            ))}
            <DeleteFriend
                isLoading={isDelLoading}
                onDel={onDelete}
                open={!!userToDelete} onClose={() => setUserToDelete(null)}/>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #ffffff;

`


const ButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    border: 1px solid #EEE;
    cursor: pointer;
`

const ItemWrapper = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #EEE;
    background: #FFF;
`

const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`
const InfoCol = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
`


const UserName = styled.div`
    color: #446E97;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
`


const Online = styled.div`
    color: #0C9D61;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`

const Offline = styled.div`
    color: #8E8E8E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`


const Avatar = styled.img`
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 40px;
    cursor: pointer;

    object-fit: cover;
`;


