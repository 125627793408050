import styled from "styled-components";
import {Header} from "../../header";
import {useUser} from "../../../hooks/use-user";
import {LinkHeader} from "../../link-header";
import {EmptyFriends} from "../../empty-friends";
import {EmptyPosts} from "../../empty-posts";
import {TourProvider, useTour} from "@reactour/tour";
import {MainInput} from "../../main-input";
import {ProfileHeader} from "../../profile-header";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Comment} from "../../comment";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {useIsRedirectCalledStore} from "../../../store/is-redirect-called-store";
import {stepsData, Tour} from "../../tour";
import {useStepsStore} from "../../../store/steps-store";
import {postEvent} from "@telegram-apps/sdk-react";


const durovComments = [{
    authorUser: {
        id: '1',
        photoURL: '/images/durov.jpg',
        isSubscribed: false,
        subscriptionCount: 777,
        username: 'Pavel Durov'
    },
    text: '🤯 I was just told that I have over 100 biological kids. How is this possible for a guy who has never been married and prefers to live alone? 🤨\n',
    id: '1',
    date: new Date().toISOString()
}, {
    authorUser: {
        id: '1',
        photoURL: '/images/durov.jpg',
        isSubscribed: false,
        subscriptionCount: 777,
        username: 'Pavel Durov'
    },
    text: 'Hello, World!',
    id: '2',
    date: new Date().toISOString()
}]

export const TourMain = () => {
    const {user} = useUser()
    const {step} = useStepsStore()
    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/tour') {
            postEvent('web_app_setup_back_button', {is_visible: false});
        } else {
            postEvent('web_app_setup_back_button', {is_visible: true});

        }
    }, [location.pathname]);


    return (

        <TourProvider
            defaultOpen={true}
            startAt={0}
            padding={{
                mask: 14,
                popover: 0,
            }}
            onClickClose={() => {
            }}
            onClickMask={() => {
            }}

            styles={
                {
                    popover: (props) => {
                        return {
                            ...props,
                            background: "transparent",
                            width: "100%",
                            maxWidth: "100%",
                            boxShadow: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxSizing: "border-box",

                        }
                    },
                }
            }
            ContentComponent={({currentStep}) => {
                return (
                    <Tour
                        content={stepsData[currentStep].content}
                    />
                )
            }}
            steps={stepsData}>
            <Container>
                <Header photoURL={user?.photoURL}/>

                <LinkHeader/>
                {step < 2 &&
                    <EmptyFriends/>
                }
                <Profile isHidden={step < 2}>
                    {/*@ts-ignore*/}
                    <ProfileHeader user={{
                        id: '1',
                        photoURL: '/images/durov.jpg',
                        isSubscribed: false,
                        subscriptionCount: 777,
                        username: 'Pavel Durov'
                    }}
                    />
                </Profile>
                <MainInput/>
                {step < 3 &&
                    <EmptyPosts/>
                }

                {step >= 3 && durovComments.map((comment) => (
                    <CommentWrapper key={comment.id}>
                        {/*@ts-ignore*/}
                        <Comment
                            onEdit={() => {
                            }}
                            onDelete={() => {
                            }}
                            isPost
                            {...comment}
                        />
                    </CommentWrapper>
                ))}

            </Container>
        </TourProvider>
    )
}


const Container = styled.div`
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
`

const Profile = styled.div<{ isHidden?: boolean }>`
    width: 100%;
    display: ${props => props.isHidden ? 'none' : 'block'};
`

const CommentWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    border-bottom: 1px solid #EEE;
`


