import {create} from 'zustand';

type State = {
    isRedirectCalled: boolean;
    setIsRedirectCalled: (isRedirectCalled: (prev: boolean) => boolean) => void;
};

export const useIsRedirectCalledStore = create<State>((set) => ({
    isRedirectCalled: false,
    setIsRedirectCalled: (isRedirectCalled) => set((state) => ({isRedirectCalled: isRedirectCalled(state.isRedirectCalled)})),
}));